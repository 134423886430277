import { ToastMessageType } from '@ajgre/toolkit';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
  animations: [
    trigger('fade', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible <=> hidden', animate('.5s ease-in-out'))
    ])
  ]
})
export class AlertComponent {
  @Input() showCloseButton = true;
  @Input() alertType!: ToastMessageType;
  @Output() closeButton = new EventEmitter<boolean>();
  isInfoAlertVisible = true;

  AlertType = ToastMessageType;

  onClose() {
    this.isInfoAlertVisible = false;
  }

  animationDone(ev: AnimationEvent) {
    if (ev.toState === 'hidden') {
      this.closeButton.emit(true);
    }
  }
}
