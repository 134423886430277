<div
  aria-live="polite"
  aria-atomic="true"
  class="toast-container"
  [@fade]="isInfoAlertVisible ? 'visible' : 'hidden'"
  (@fade.done)="animationDone($event)">
  <div
    class="toast show"
    role="alert"
    [attr.role]="alertType === AlertType.Error ? 'alert' : 'status'"
    [attr.aria-live]="alertType === AlertType.Error ? 'assertive' : 'polite'"
    aria-atomic="true"
    [class.re-success]="alertType === AlertType.Success"
    [class.re-warning]="alertType === AlertType.Warning"
    [class.re-error]="alertType === AlertType.Error"
    [class.re-information]="alertType === AlertType.Information">
    <div class="toast-body">
      <div class="alert-content">
        <ng-content></ng-content>
      </div>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="onClose()"
        *ngIf="showCloseButton"></button>
    </div>
  </div>
</div>
