<div [class.re-inline-label]="formConfig.inlineLabel">
  <re-label [formConfig]="formConfig" [disabled]="disabledState"></re-label>
  <div class="re-control">
    <select
      #select
      class="form-select"
      [class.re-unselected]="!selectedValue"
      [id]="formConfig.id"
      [attr.name]="formConfig.name"
      [attr.aria-label]="formConfig.ariaLabel"
      [attr.aria-describedby]="ariaDescribedBy()"
      [attr.ngbautofocus]="formConfig.autoFocus ? '' : undefined"
      [disabled]="disabledState"
      (change)="onChange(select.value)"
      (blur)="onBlur($event)">
      <option value="" *ngIf="formConfig.placeholder; else noPlaceholder">
        {{ formConfig.placeholder }}
      </option>
      <option
        *ngFor="let option of options"
        [value]="valueProperty(option)"
        [disabled]="disabledProperty(option)">
        {{ displayProperty(option) }}
      </option>
    </select>
    <div *ngIf="displayErrorMessage()" class="form-text">{{ errorMessage }}</div>
  </div>
</div>

<ng-template #noPlaceholder><option disabled hidden></option></ng-template>
