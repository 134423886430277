import { LabelComponent, SelectFormConfig } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-select',
  standalone: true,
  imports: [CommonModule, LabelComponent],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent<T> implements ControlValueAccessor, OnChanges, AfterViewInit {
  @Input() formControlName?: string;
  @Input() formConfig!: SelectFormConfig;
  @Input() value = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() disabled = false;
  @Input() errorMessage = '';
  @Input() options: T[] = [];
  @ViewChild('select', { static: true }) select = {} as ElementRef<HTMLSelectElement>;

  disabledState = false;
  selectedValue = '';

  private propagateChange: (_: unknown) => unknown = () => null;
  private propagateTouch: (_: unknown) => unknown = () => null;

  @HostBinding('class.ng-invalid') get invalid() {
    return !this.formControlName && this.errorMessage && !this.disabled;
  }
  @HostBinding('class.ng-touched') get touched() {
    return !this.formControlName && this.errorMessage && !this.disabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.formControlName) {
      if (changes['value']) {
        this.writeValue(changes['value'].currentValue);
      }
      if (changes['disabled']) {
        this.setDisabledState(changes['disabled'].currentValue);
      }
    }

    if (changes['options'] && !changes['options'].firstChange) {
      setTimeout(() => {
        const optionAvailable = this.optionAvailable();

        this.writeValue(optionAvailable ? this.selectedValue : '');
        this.onChange(optionAvailable ? this.selectedValue : '');
      });
    }
  }

  ngAfterViewInit() {
    this.writeValue(this.selectedValue);
  }

  displayErrorMessage(): boolean {
    return !!this.errorMessage && (this.formConfig.showErrorMessage ?? true) && !this.disabled;
  }

  ariaDescribedBy(): string | undefined {
    return this.formConfig.label && this.formConfig.labelHint
      ? `${this.formConfig.id}-hint`
      : undefined;
  }

  valueProperty(option: T): string {
    if (typeof option === 'string') {
      return option;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.formConfig.valueProperty ? (option as any)[this.formConfig.valueProperty] : '';
  }

  disabledProperty(option: T) {
    if (typeof option === 'string') {
      return false;
    }

    return this.formConfig.disabledProperty
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (option as any)[this.formConfig.disabledProperty]
      : false;
  }

  displayProperty(option: T): string {
    if (typeof option === 'string') {
      return option;
    }

    return this.formConfig.displayProperty
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (option as any)[this.formConfig.displayProperty]
      : '';
  }

  writeValue(value: string) {
    this.selectedValue = value ?? '';
    this.select.nativeElement.value = this.selectedValue;

    setTimeout(() => {
      if (this.selectedValue && !this.optionAvailable()) {
        this.onChange('');
      }
    });
  }

  onChange(value: string) {
    this.selectedValue = value;

    this.propagateChange(value);

    this.valueChange.emit(value);
  }

  onBlur(event: FocusEvent) {
    this.propagateTouch(event);
  }

  registerOnChange(fn: (_: unknown) => unknown) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => unknown) {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabledState = isDisabled;
  }

  private optionAvailable(): T | undefined {
    return this.options.find((option) =>
      typeof option === 'string'
        ? option === this.selectedValue
        : this.formConfig.valueProperty
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (option as any)[this.formConfig.valueProperty]?.toString() === this.selectedValue
          : false
    );
  }
}
